@import 'common/scss/utils.scss';

@mixin h4 {
  font-weight: 700;
  color: $surface-high;
}

#admin-manage-edd-ra-content {
  margin-top: 24px;

  .edd-ra-config-header {
    @include flex(center, initial);
    gap: 16px;
    article {
      flex: 1;
      border-radius: 8px;
      & > div {
        @include flex(center, initial);
        gap: 16px;
        padding: 11px 16px;
        background-color: $surface-100;
        border: 1px solid $gray-300;
        border-radius: 8px;
        @include h4;
      }

      &.disabled {
        opacity: 0.6;
        cursor: default;
        pointer-events: none;
        background-color: $gray-100;
        &.active {
          background-color: $primary-10;
        }
        & > div {
          background-color: transparent;
        }
      }

      &:last-of-type {
        background-color: transparent;
        & > div {
          background-color: transparent;
          border: none;
        }
      }

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: $surface-medium;
      }
      .content {
        margin-left: auto;
      }

      .jack__select {
        width: 192px;
        height: 48px;
        .jack__select__button {
          font-weight: 400;
          padding: 0 16px;
          .jack__select__arrow__icon {
            z-index: 101;
          }
        }
        .jack__select__options {
          top: 0px !important;
          border: 1px solid $primary-80 !important;
          border-radius: 8px !important;
        }
      }
    }
  }

  .button-group {
    @include flex(center, flex-end);
    margin-left: auto;
    gap: 8px;

    button {
      padding: 8px 12px;
      border: 1px solid $gray-200;
      border-radius: 8px;

      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.25rem;
      background-color: $surface-100;

      color: $surface-medium;

      &:last-child {
        color: $primary-80;
      }

      p {
        margin-left: auto;
        margin-right: 96px;
        font-weight: 400;
      }

      &[disabled] {
        color: $surface-disabled;
      }
    }
  }
}
