@import 'common/scss/utils.scss';

#kyc-find-id-form-field {
  .jack__input__container.success {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 16px;
      height: 12px;
      right: 24px;
      top: 50%;
      transform: translateY(-50%);
      background: url('/assets/img/kyc_icons.svg') no-repeat -24px -251px;
    }
  }

  .api-check-message {
    font-size: 12px;
    color: $primary-100;
    margin-top: 10px;
    &.error {
      color: $error-100;
    }
  }

  .jack__validator__button {
    background-color: $primary-100;
    margin-top: 24px;
    margin-bottom: 30px;
  }
}
