@import 'common/scss/utils.scss';

#admin-manage-edd-criterion-content {
  margin-top: 32px;
  @include flex(initial, initial);
  flex-direction: column;
  gap: 32px;

  & > section {
    h3 {
      font-size: 20px;
      font-weight: 500;
      color: $surface-medium;
    }

    .criterion-content {
      margin-top: 16px;
      background-color: $surface-100;
      padding: 32px;
    }
  }
}
