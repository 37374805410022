@import 'common/scss/utils.scss';

#root-guidance-privacy-table {
  .alchera-content-controller {
    .control-field {
      h2 {
        a {
          color: $surface-disabled;
          &.active {
            color: inherit;
          }
        }

        a:last-child {
          margin-left: 28px;
        }
      }
    }
  }

  .jack_table__head {
    &:first-child {
      padding-left: 10%;
    }
  }

  .jack_table__body {
    .jack_table__row {
      .jack_table__tr {
        &:first-child {
          padding-left: 10%;
        }

        .expand-icon {
          @include flex(center, center);
          width: 48px;
          height: 48px;
          cursor: pointer;

          &.expanded {
            &::before {
              background-position: -25px -101px;
            }
          }

          &::before {
            content: '';
            display: block;
            background: kyc-icon(-44px, -115px);
            width: 17px;
            height: 10px;
          }
        }
      }
    }
  }
}
