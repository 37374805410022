@import 'common/scss/utils.scss';

section.root-page-client-expand-addons {
  @include flex(initial, initial);
  grid-column: 1 / 3;
  border-top: 1px solid $gray-200;
  padding-top: 16px;

  .extras-title {
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: $surface-high;
    @include flex(center, initial);
    &::before {
      content: '';
      display: block;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: $surface-high;
      margin-right: 6px;
    }
  }

  @mixin h5 {
    h5 {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 10px;
    }
  }

  .addons {
    @include h5;
    width: 100%;
    p {
      line-height: 22px;
      margin-bottom: 4px;
    }

    .addons-container {
      @include flex(flex-start, initial);
      gap: 40px;

      & > div {
        flex-basis: 200px;
      }

      .category {
        padding: 4px 16px;

        .cate-title {
          @include flex(center, space-between);
          position: relative;
          padding-left: 14px;

          &::before {
            content: 'ㄴ';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
          }

          span {
            flex: auto;
            color: $primary-80;
            margin-left: 4px;
          }
        }

        .checkbox-list {
          padding: 8px 0;
        }
      }
      .addons-flex {
        @include flex(initial, initial);
        flex: 1;
        gap: 40px;

        .addons-notification {
          padding-left: 10px;
        }

        .addons-reuse,
        .addons-theme {
          @include flex(initial, initial);
          flex-direction: column;
          flex-basis: 200px;

          .reuse-label,
          .theme-label {
            @include flex(center, space-between);
          }

          .category {
            @include flex(initial, initial);
            gap: 4px;
          }
        }
      }
    }
  }
}

@include desktop {
  section.root-page-client-expand-addons {
    grid-column: initial;
    border-top: none;
    padding-top: 0;
    .addons {
      .addons-container {
        .addons-notification {
          padding-left: 0;
        }

        .addons-flex {
          flex-direction: column;
          gap: 4px;

          .addons-reuse,
          .addons-theme {
            flex-basis: 0;
          }
        }
      }
    }
  }
}
