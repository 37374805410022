@import 'common/scss/utils.scss';

#admin-manage-customfield-setting-direct {
  margin-top: 48px;
  .header {
    .button-group {
      .modal-button {
        @include flex(center, initial);
        color: $surface-100 !important;
        background: $primary-80;
        border-radius: 8px;
        padding: 8px 10px;
        // width: 60px;
        height: 36px;

        font-size: 0.875rem;
        line-height: 1.5rem;
        border: none;
        .plus-icon {
          margin-right: 3px;
          background: url('/assets/img/kyc_icons.svg') no-repeat -100px -229px;

          width: 10px;
          height: 10px;
        }
      }
      .edit-button {
        color: $primary-80 !important;
      }
      &.edit-mode {
        .cancel-button {
          color: $surface-medium !important;
        }
        .confirm-button {
          color: $primary-80 !important;
        }
      }
    }
  }
  .content {
    .jack_table {
      overflow: visible;
      .jack_table_header__wrapper {
        background: $gray-100;
        border: none;
        .jack_table__th {
          padding: 12px 8px;

          .jack_table__text {
            font-size: 1rem;
            line-height: 1.5rem;
            color: $surface-medium;
          }
        }
      }
      .jack_table__body {
        height: auto;
        min-height: 0px;
        overflow: visible !important;

        .rowData.readonly {
          // 편집모드인 경우 select readonly 처리
          .jack__select.readonly.disabled {
            background-color: transparent;
          }
          .button__title {
            color: $surface-medium;
          }
          .jack__select__arrow__icon {
            visibility: hidden;
          }

          // 편집모드인 경우 textare color
          .jack__textarea__container {
            background-color: transparent;
            textarea {
              color: $surface-medium;
              background-color: transparent;
            }
          }
        }
        .jack_table__row {
          cursor: pointer;
          border: none;
        }

        .jack_table__td {
          padding: 12px 5px;
          overflow: initial;

          .jack_table__td__context {
            width: calc(100% - 10px);
            max-width: calc(100% - 10px);
          }
          & input {
            height: 48px;
            padding: 0 16px;
          }

          & .text-node {
            width: 100%;
            overflow: initial;

            /* td 안에 select 높이 설정 */
            .jack__select {
              height: 48px;
              border-radius: 8px;
              .jack__select__options {
                top: 48px !important;
                .jack__select__item {
                  height: 48px;
                }
              }

              /* select 비활성화 */
              &.disabled {
                .jack__select__button {
                  background-color: transparent;
                  .button__title {
                    color: $surface-medium;
                  }
                }
                .jack__select__arrow__icon {
                  visibility: hidden;
                }
              }

              &.error {
                border: none;
                .jack__select__button {
                  border-color: $error-100 !important;
                  &.active {
                    border-bottom: none !important;
                  }
                }
                .jack__select__options {
                  border-color: $error-100;
                }
              }
            }

            /* td 안에 textarea 여백 조정 */
            .jack__textarea__container {
              & > div {
                padding: 0;
              }
              textarea {
                overflow: hidden;
              }
            }
          }
        }

        .jack__validator__error__message {
          margin-top: 0;
          padding-left: 54px !important;
        }

        .jack__no__data {
          height: 0;
          overflow: hidden;
          .content__wrapper {
            display: none;
          }
        }

        /* 항목 아이콘 */
        .admin-manage-subscribe-setting-custom-direct {
          &.allign {
            background: url('/assets/img/kyc_icons.svg') no-repeat -260px -174px;
            width: 17px;
            height: 8px;
            margin: 0 auto;
          }
          &.remove {
            background: url('/assets/img/kyc_icons.svg') no-repeat -47px -219px;
            width: 30px;
            height: 30px;
            cursor: pointer;
            padding: 10px;
            display: block;
            margin: 0 auto;
          }
          &.type {
            .text {
              @include flex(center, initial);
              &::before {
                margin-right: 8px;
                content: '';
                display: block;
                background: url('/assets/img/kyc_icons.svg') no-repeat -228px -133px;
                width: 15px;
                height: 16px;
              }
            }
            .file {
              @include flex(center, initial);
              &::before {
                margin-right: 8px;
                content: '';
                display: block;
                background: url('/assets/img/kyc_icons.svg') no-repeat -200px -130px;
                width: 18px;
                height: 21px;
              }
            }
          }
        }
      }
    }

    /* 테이블 하단 +추가 버튼 */
    .custom__no__data {
      border: 1px dashed $surface-disabled;
      @include flex(center, center);
      min-height: 64px;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      .custom__no__data__icon {
        width: 20px;
        height: 20px;
        background: url('/assets/img/kyc_icons.svg') no-repeat -76px -222px;
      }
    }
  }
}
