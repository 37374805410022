@import 'common/scss/utils.scss';

#kyc-sign-up-page {
  .information {
    .duplicate-check {
      @include flex(initial, initial);
      flex-wrap: wrap;
      margin-top: 8px;
      margin-bottom: 20px;
      gap: 10px;

      .jack__input__container {
        width: calc(100% - 94px);
      }

      .jack__button {
        width: 84px;
        height: 56px;
        flex-shrink: 0;
        background-color: $surface-medium;
      }

      .api-check-message {
        font-size: 12px;
        color: $primary-100;
        &.error {
          color: $error-100;
        }
      }
    }

    .next-field {
      @include flex(center, flex-end);
      width: 100%;
      gap: 10px;
      margin-top: 32px;

      button {
        &:first-child {
          @include flex(center, center);
          flex: 2;
          width: 120px;
          height: 60px;
          border-radius: 8px;
          background-color: #f6f6f6;

          & > div {
            color: $surface-medium !important;
            font-weight: 500;
            font-size: 16px;
          }
        }

        &:last-child {
          @include flex(center, center);
          flex: 3;
          width: 120px;
          height: 60px;
          border-radius: 8px;
          background-color: $primary-100;

          & > div {
            font-weight: 500;
            font-size: 16px;
          }
        }
      }
    }
  }
}
