@import 'common/scss/utils.scss';

.admin-manage-custom-forbidden {
  background-color: $surface-100;
  @include flex(center, center);
  flex-direction: column;
  color: $surface-medium;
  min-height: calc(100vh - 300px);

  &-icon {
    background: kyc-icon(-662px, -165px);
    width: 64px;
    height: 64px;
    margin-bottom: 16px;
  }
}
