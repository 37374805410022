@import 'common/scss/utils.scss';

.ra-criterion-setting {
  .button-group {
    @include flex(center, flex-end);
    gap: 8px;

    button {
      padding: 8px 12px;
      @include border($gray-200);
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.25rem;
      background-color: $surface-100;
      color: $surface-medium;

      &:last-child {
        color: $primary-80;
      }

      p {
        margin-left: auto;
        margin-right: 96px;
        font-weight: 400;
      }

      &[disabled] {
        color: $surface-disabled;
      }
    }
  }

  .criterion-label {
    margin-top: 48px;
    padding: 0 64px;
    display: flex;

    span {
      transition: all 0.3s ease;
      &:nth-child(1) {
        color: $primary-40;
      }
      &:nth-child(2) {
        color: $primary-60;
      }
      &:nth-child(3) {
        color: $primary-100;
      }
    }
  }
  .criterion-slider {
    margin-top: 8px;
    padding: 0 56px;
    @include flex(center, initial);

    .min,
    .max {
      width: 46px;
      height: 6px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        width: 14px;
        height: 14px;
        border-radius: 50%;
      }
    }

    .min {
      background-color: $primary-40;
      &::after {
        background-color: $gray-500;
        top: -4px;
        left: -7px;
      }
    }
    .max {
      background-color: $primary-100;
      &::after {
        background-color: $primary-100;
        top: -4px;
        right: -7px;
      }
    }

    .custom__multi__range__slider {
      position: relative;
      flex: 1;

      input[type='range'] {
        position: absolute;
        top: 0;
        width: 100%;
        height: 0;
        pointer-events: none;
        appearance: none;
        -webkit-appearance: none;

        &.custom__range__slider__lower::-webkit-slider-thumb {
          width: 16px;
          height: 16px;
          background: $primary-40;
        }
        &.custom__range__slider__upper::-webkit-slider-thumb {
          background: $primary-60;
          width: 16px;
          height: 16px;
        }

        &::-moz-range-thumb {
          appearance: none;
          -webkit-appearance: none;
          pointer-events: all;
          cursor: pointer;
        }
        &::-webkit-slider-thumb {
          appearance: none;
          -webkit-appearance: none;
          pointer-events: all;
          cursor: pointer;
          transform: translateY(3px);
          // transform: translateY(-33px);
          border: 2px solid $surface-100;
          border-radius: 50%;
          box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 1px 8px rgba(0, 0, 0, 0.1);
        }
      }
      // gradient bar
      .range {
        height: 6px;
      }
    }
  }
  .criterion-score {
    margin-top: 8px;
    padding: 0 52px;
    @include flex(center, initial);
    position: relative;

    & > div {
      @include flex(center, initial);
      font-size: 14px;
      font-weight: 700;
      position: relative;
      height: 34px;
      white-space: nowrap;

      &:first-of-type {
        color: $gray-500;
      }
      &:last-of-type {
        color: $primary-100;
        right: -24px;
      }
      &:not(:first-of-type, :last-of-type) {
        flex: 1;
      }
    }

    .jack__input__container {
      &.disabled {
        .jack__input {
          background-color: transparent;
          border: none;
          color: inherit;
        }
      }

      .jack__input {
        padding: 0 8px;
        text-align: right;
      }
    }
  }
}
