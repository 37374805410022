@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');
@import './elements/kyc_validator_error.scss';
@import './elements/kyc_table.scss';
@import './elements/kyc_alert.scss';
@import './elements/kyc_switch.scss';
@import './elements/kyc_input.scss';
@import './elements/kyc_button.scss';
@import './elements/kyc_radio.scss';
@import './elements/kyc_search.scss';
@import './elements/kyc_select.scss';
@import './elements/kyc_noData.scss';
@import './elements/kyc_textarea.scss';
@import './elements/kyc_checkbox.scss';
@import './elements/kyc_accordion.scss';

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

body {
  font-family: 'Noto Sans KR', sans-serif;
  background-color: $primary-5;
  color: #191919;
}
