@import 'common/scss/utils.scss';

section.customfield-optional-content {
  .content {
    @include flex(initial, initial);
    gap: 16px;
    flex-wrap: wrap;
  }

  article.customfield-option {
    width: 500px;

    header {
      @include flex(center, space-between);
      padding: 8px 16px;
      border-radius: 8px;
      font-weight: 500;
      font-size: 14px;
      color: $surface-medium;
      background-color: $gray-100;

      &.active {
        color: $surface-high;
        background-color: $primary-5;
      }
    }

    ul {
      margin-top: 10px;
      display: grid;
      grid-template-columns: repeat(3, auto);
      grid-row-gap: 6px;

      li {
        @include flex(center, initial);
        font-size: 14px;
        color: $surface-medium;
        // min-width: 120px;
        // flex: 1 0 0;
        padding: 0 18px;

        &::before {
          content: '';
          display: block;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          margin-right: 10px;
          background-color: $surface-medium;
        }
      }
    }
  }
}
