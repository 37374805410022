@import 'common/scss/utils.scss';

#admin-manage-content-navigator {
  .control-field {
    h2 {
      a {
        color: $surface-disabled;
        &.active {
          color: inherit;
        }
      }

      a:not(:first-child) {
        margin-left: 28px;
      }
    }
  }
}
