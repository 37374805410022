@import 'common/scss/utils.scss';

#reviewer-review-table-expand-content-custom {
  @include flex(initial, initial);
  width: 100%;
  padding: 24px 56px;

  ul.info-section {
    li {
      @include flex(center, initial);
      // height: 40px;
      margin-bottom: 16px;
      h4 {
        flex-shrink: 0;
        width: 112px;
        margin-right: 38px;
        font-size: 16px;
        font-weight: 500;
        // elipsis
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow-x: hidden;
      }

      & > p {
        font-size: 16px;
        &.warning {
          color: $error-100;
        }
        &.disabled {
          color: $surface-disabled;
        }
      }
      .jack__button {
        width: 110px;
        border: 1px solid $gray-200;
        border-radius: 8px;
        padding: 8px 12px;
        color: $surface-medium;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.25rem;
      }
    }
  }
}

.reviewer-image-section-modal {
  &.expired {
    border: 0;

    .close-btn {
      top: -30px;
      right: 2px;
    }
    i.image-expired {
      width: 640px;
      height: 400px;
      background: url('/assets/img/kyc_icons.svg') no-repeat -740px -330px;
      position: relative;
    }
  }
}
