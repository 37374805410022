@import './utils.scss';

.jack__input__container {
  position: relative;
  display: flex;
  flex-wrap: wrap;

  &.disabled {
    .jack__input {
      background-color: #f5f5f5;
      color: #a5adba;
    }
  }

  &.error {
    .jack__input {
      border: 1px solid #ff5974 !important;
    }
  }

  &.readonly {
    .jack__input {
      background-color: #f5f5f5;
    }
  }

  input.jack__input {
    flex-shrink: 0;
    flex-grow: 1;
    width: 100%;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    padding-left: 14px;
    font-size: 14px;
    color: #191919;
    outline: none;
    transition: all 0.2s ease;
    box-sizing: border-box;
    font-family: inherit;

    &[type='password'] {
      padding-right: 36px;
    }

    &::placeholder {
      font-size: inherit;
    }
  }

  i.input__pw__lock__icon {
    @include flex(center, center);
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: 8px;
    width: 24px;
    height: 24px;
    cursor: pointer;

    &.disabled {
      &::before {
        background-position: -264px -100px;
      }
    }

    &.open {
      &::before {
        width: 14px;
        height: 13px;
        background: url('../assets/ssr_password_lock_open.svg') no-repeat center;
      }
    }

    &::before {
      content: '';
      display: block;
      top: 0;
      right: 0;
      width: 10px;
      height: 12px;
      background: url('../assets/ssr_password_lock.svg') no-repeat center;
    }
  }

  .jack__validator__error__message {
    flex: 1;
  }
}
