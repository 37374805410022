@import 'common/scss/utils.scss';

aside.root-page-client-expand-admins {
  padding: 0 40px;

  .extras-title {
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: $surface-high;
    @include flex(center, initial);
    &::before {
      content: '';
      display: block;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: $surface-high;
      margin-right: 6px;
    }
  }

  @mixin h5 {
    h5 {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 10px;
    }
  }

  .admin-count {
    @include h5;

    .count {
      @include flex(center, initial);
      line-height: 22px;
      margin-bottom: 4px;
      span {
        color: $primary-80;
        margin-left: 4px;
      }

      .jack__input__container {
        margin: 0 4px;
      }
    }
  }
}

@include desktop {
  aside.root-page-client-expand-admins {
    @include flex(initial, space-between);
    flex-direction: row;
    padding: 0;
    .admin-count {
      flex-grow: 1;
      max-width: 190px;
    }
  }
}
