@import 'common/scss/utils.scss';

article.root-page-client-expand-functions {
  flex: 4;
  h5 {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 24px;
  }
  ul.setting-row {
    @include flex(initial, space-between);
    gap: 16px;

    li.setting-col {
      width: 100%;

      .switch-box {
        @include flex(center, space-between);
        width: 100%;
        height: 40px;
        padding: 0 16px;
        background-color: $gray-100;
        font-size: 16px;
        border-radius: 8px;

        &.active {
          background-color: $primary-5;
          color: $primary-100;
        }
        &.inactive {
          color: $surface-disabled;
        }
      }

      .explain {
        font-size: 14px;
        margin: 10px 0 16px 0;
        text-indent: 16px;
      }

      hr {
        border: none;
        border-top: 2px dashed $gray-200;
        height: 0;
        margin-bottom: 16px;
      }

      .category {
        padding: 4px 16px;

        .category {
          padding: 8px 0 0 16px;
        }

        .cate-title {
          @include flex(center, space-between);
          position: relative;
          padding-left: 14px;
          & > div {
            @include flex(center, initial);
          }

          &::before {
            content: 'ㄴ';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
          }
          .tooltip {
            @include flex(center, initial);
            position: relative;
            cursor: pointer;

            margin-left: 6px;
            // display: block;
            background: url('/assets/img/kyc_icons.svg') no-repeat -196px -318px;
            width: 16px;
            height: 16px;
          }
        }

        .auth-code-message {
          margin-top: 8px;

          .jack__input {
            width: 148px;
            height: 40px;
            padding: 8px 16px;
            border: 1px solid $gray-300;
            border-radius: 8px;
            color: $surface-medium;

            font-size: 0.875rem;
            line-height: 1.5rem;
            &::placeholder {
              font-size: 0.875rem;
              line-height: 1.5rem;
              color: $surface-disabled;
            }
          }

          /* Surface */

          // background: $surface-high;
          /* Gray/300 */
        }

        .checkbox-list {
          padding: 8px 0;
        }
      }

      &.account {
        & > div:nth-last-of-type(2) {
          margin-bottom: 66px;
        }
      }
    }
  }
}

#root-page-client-expand-popover.description {
  z-index: 10;
  background: url('/assets/img/kyc_icons.svg') no-repeat -455px -308px;
  width: 234px;
  height: 80px;

  position: absolute;
  left: 0%;
  top: 100%;
  transform: translateX(-9%);
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: $surface-medium;
  padding: 25px 25px 10px 25px;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@include desktop {
  article.root-page-client-expand-functions {
    grid-column: 1 / 3;
  }
}
