@import 'common/scss/utils.scss';

@mixin h4 {
  h4 {
    font-size: 14px;
    font-weight: 400;
    color: $surface-medium;
  }
}

#modal-customfield-direct-dropdown {
  .condition {
    @include h4;

    &:not(.treeview) {
      .item {
        @include flex(initial, initial);
        flex-direction: column;
        gap: 8px;
        margin-bottom: 16px;
      }
    }
    &.treeview {
      .item {
        @include flex(center, space-between);
        flex-direction: initial;
        margin-bottom: 20px;
        & > div {
          @include flex(initial, space-between);
          gap: 8px;
          button {
            width: 28px;
            height: 28px;
            border: 1px solid $gray-300;
            border-radius: 8px;
            &:first-child {
              background: kyc-icon(-71px, -220px);
            }
            &:last-child {
              background: kyc-icon(-48px, -220px);
            }
          }
        }
      }
    }
  }

  .treeview__list {
    // max-height: 277px;
    max-height: 577px;
    overflow: auto;
  }
}
