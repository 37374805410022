@import 'common/scss/utils.scss';

.ra-realscore-ratype {
  margin-top: 8px;
  padding: 24px 32px;
  background-color: $surface-100;

  .accordion__title {
    @include flex(center, space-between);
    flex: 1;
    font-size: 16px;
    font-weight: 700;

    span:last-child {
      color: $primary-80;
      margin-right: 56px;
    }
  }

  .jack__accordion__section {
    .jack__accordion_button {
      background-color: transparent;
      padding: 0;
    }
    .accordion__content {
      background-color: transparent;
    }

    .control-pannel {
      @include flex(center, flex-end);
      margin-top: 8px;

      button {
        @include border($gray-300);
        background-color: $gray-100;
        padding: 8px 16px;
        color: $surface-medium;
        transition: all 0.3s ease;

        &:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right: 1px solid $primary-80;
        }
        &:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-left: 0;
        }
        &.active {
          background-color: $surface-100;
          color: $surface-high;
          border-color: $primary-80;
        }
      }
    }

    .ra-realscore-table {
      margin-top: 8px;
      .table {
        &-head,
        &-row {
          padding: 8px;
          border-bottom: 1px solid $gray-200;

          span:not(.table-row-detail span):not(.warn__labels, .warn__labels span) {
            font-size: 14px;
            width: 22%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            &:first-of-type {
              width: 5%;
              text-align: center;
            }
            &:nth-child(2) {
              width: 14%;
            }
            &.realscore {
              // width: 15%;
              width: auto;
              min-width: 40px;
              text-align: right;
              margin-right: 48px;
              color: $primary-100;
            }
          }
          span.warn__labels {
            @include flex(initial, initial);
            width: 60%;
            gap: 4px;
            text-overflow: initial;
            overflow: initial;
            white-space: initial;

            span {
              @include flex(initial, initial);
              font-size: 12px;
              color: $surface-medium;
              border: 1px solid $surface-disabled;
              border-radius: 100px;
              padding: 4px 8px;
            }
          }
        }

        &-head {
          @include flex(center, space-between);
          border-top: 1px solid $gray-200;
          span {
            color: $surface-medium !important;
          }
        }
        &-row {
          @include flex(initial, center);
          flex-direction: column;
          position: relative;
          i {
            position: absolute;
            right: 8px;
            width: 16px;
            height: 16px;
            background: kyc-icon(-24px, -110px);
            cursor: pointer;
            scale: 0.7;

            &.expand {
              background: kyc-icon(-25px, -96px);
            }
          }

          &-content {
            @include flex(center, space-between);
          }
          &-detail {
            @include flex(center, flex-end);

            div {
              @include flex(center, initial);
              gap: 8px;
              padding: 0 16px;

              &:first-of-type {
                border-right: 1px solid $gray-300;
              }
              &:last-of-type {
                margin-right: 32px;
              }
            }
            span {
              font-size: 13px;
              color: $surface-high;
              &.label {
                font-size: 12px;
                color: $surface-medium;
              }
              &.score {
                width: 40px;
                text-align: right;
                white-space: nowrap;
              }

              &:last-of-type {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}
